/**
 * @description     \ Finance service express routes
 * @baseURL         \ https://finance-service
 */
 import config from "../config"

 /**
   * @method     \ GET
   * @param      \ int
   * @return     \ json
   */
 const getPositions = async () => {
   const options = {
     base: "https://finance-service",
     params: false,
     payload: false,
     endpoint: `/api/position`,
     method: "GET"
   }
   
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
   
   const res = await data.json();
   
   return res;
 };
 
 export {
     getPositions
 };