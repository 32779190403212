/**
 * @service         \ https://contacts-service.rrdevelopment.co.uk
 * @middleware      \ https://appURL/api/contact-service/clients
 * @description     \ Contacts service client express routes
 * @baseURL         \ https://contacts-service.rrdevelopment.co.uk/api/clients
 */

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ GET
 * @param  \ int
 * @return \ json
 */

import config from "../config";

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ PUT
 * @param  \ obj
 * @return \ json
 */


const getPersonnel = async () => {
  const options = {
    base: "https://personnel-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/personnelIndex`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getPersonnelSingle = async (id) => {
  const options = {
    base: "https://personnel-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/personnelShow/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const savePersonnel = async (obj) => {
  const options = {
    base: "https://personnel-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/personnelRegister/${obj.id}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};




const updatePersonnel = async (obj, id) => {
  const options = {
    base: "https://personnel-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/personnelUpdate/${id}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.statusCode = data.status;

  return res;
};


/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ GET
 * @param  \ int
 * @return \ json
 */


const getPersonnelCreateSetup = async () => {
  const options = {
    base: "https://personnel-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/personnelCreateSetup`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};



const getPersonnelUpdateSetup = async (id) => {
  const options = {
    base: "https://personnel-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/personnelUpdateSetup/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const deletePersonnel = async (id) => {
  const options = {
    base: "https://personnel-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/personnelDeregister/${id}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


export {
  getPersonnel,
  savePersonnel,
  updatePersonnel,
  getPersonnelUpdateSetup,
  getPersonnelCreateSetup,
  deletePersonnel,
  getPersonnelSingle
};
