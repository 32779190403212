/**
 * @service         \ https://contacts-service.rrdevelopment.co.uk
 * @middleware      \ https://appURL/api/contact-service/clients
 * @description     \ Contacts service client express routes
 * @baseURL         \ https://contacts-service.rrdevelopment.co.uk/api/clients
 */
import config from "../config";

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ GET
 * @param  \ int
 * @return \ json
 */




const getDraftCostings = async (obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/costings/draftIndex`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const getActiveCostings = async (obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/costings/approvedIndex`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const getCompleteCostings = async (obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/costings/completedIndex`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const getReviewCostings = async (obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/costings/reviewIndex`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const getRestoreCostings = async (obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/costings/restoreIndex`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const getCostingsSingle = async (id) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/costings/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ POST
 * @param  \ obj
 * @return \ json
 */

const saveCostings = async (obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/costings`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ PUT
 * @param  \ obj
 * @return \ json
 */


const updateCostings = async (id, obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/costings/${id}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ GET
 * @param  \ int
 * @return \ json
 */



const deleteCostings = async (id) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/costings/${id}`,
    method: "DELETE"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const restoreCosting = async (id) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/costings/restore/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const duplicateCostings = async (id, obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/costings/copy/${id}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const deleteLine = async (id) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/costings/lineDelete/${id}`,
    method: "DELETE"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};



// Charge Codes




const chargeCodeSync = async (arr) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: arr,
    endpoint: `/api/chargeCodeSync`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const chargeCodeIndex = async () => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/chargeCode`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const chargeCodeDelete = async (id) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/chargeCode/${id}`,
    method: "DELETE"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const chargeCodeSave = async (obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/chargeCode`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

// Payment Method



const paymentMethodSync = async (arr) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: arr,
    endpoint: `/api/paymentMethodSync`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const paymentMethodIndex = async () => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/paymentMethod`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const paymentMethodDelete = async (id) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/paymentMethod/${id}`,
    method: "DELETE"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const paymentMethodSave = async (obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/paymentMethod`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


// Periodic Code



const periodicCodeSync = async (arr) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: arr,
    endpoint: `/api/periodicCodeSync`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};



const periodicCodeIndex = async () => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/periodicCode`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const periodicCodeDelete = async (id) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/periodicCode/${id}`,
    method: "DELETE"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const periodicCodeSave = async (obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/periodicCode`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

//Notifications

const saveNotifications = async (arr) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: arr,
    endpoint: `/api/notifications`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const getNotifications = async () => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/notifications`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

// Approval

const requestApproval = async (id, obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/costings/requestApproval/${id}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const getLiveLines = async (id) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/liveLines/costingIndex/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

// Approval

const saveLiveLines = async (id, obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/liveLines/costingUpdate/${id}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const deleteLiveLabour = async (id) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/liveLines/workedDelete/${id}`,
    method: "DELETE"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const deleteVariation = async (id) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/liveLines/variationDelete/${id}`,
    method: "DELETE"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const updateApproved = async (id, obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/costings/updateApproved/${id}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const updateCompleted = async (id, obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/costings/updateCompleted/${id}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const approveVariation = async (id, obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/liveLines/updateApproved/${id}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const downloadDocument = async (id) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/documents/url/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const clientCreditCheck = async (id) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/creditChecks/clientCurrent/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const getCreditCheck = async (id) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/creditChecks/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const saveCreditCheck = async (obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/creditChecks`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const saveTotals = async (obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/costings/totals`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const getLabourReport = async (obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/reports/labour`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const getProfitReport = async (obj) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/reports/profitability`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const updateCostingCheck = async (costingId, payload) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: payload,
    endpoint: `/api/single/abc/updateStatus/${costingId}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const overviewStore = async (payload) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: payload,
    endpoint: `/api/single/overview`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const overviewUpdate = async (costingId, payload) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: payload,
    endpoint: `/api/single/overview/${costingId}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const overviewDelete = async (costingId) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/single/overview/${costingId}`,
    method: "DELETE"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const revenueStore = async (payload) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: payload,
    endpoint: `/api/single/revenue`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const revenueUpdate = async (revId, payload) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: payload,
    endpoint: `/api/single/revenue/${revId}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const revenueDelete = async (revId) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/single/revenue/${revId}`,
    method: "DELETE"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const labourStore = async (payload) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: payload,
    endpoint: `/api/single/labour`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const labourUpdate = async (labId, payload) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: payload,
    endpoint: `/api/single/labour/${labId}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const labourDelete = async (labId) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/single/labour/${labId}`,
    method: "DELETE"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const variationStore = async (payload) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: payload,
    endpoint: `/api/single/variation`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const variationUpdate = async (varId, payload) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: payload,
    endpoint: `/api/single/variation/${varId}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const variationDelete = async (varId) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/single/variation/${varId}`,
    method: "DELETE"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const liveLabourStore = async (payload) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: payload,
    endpoint: `/api/single/liveLabour`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const liveLabourUpdate = async (labId, payload) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: payload,
    endpoint: `/api/single/liveLabour/${labId}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

const liveLabourDelete = async (labId) => {
  const options = {
    base: "https://costings-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/single/liveLabour/${labId}`,
    method: "DELETE"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();
  res.ok = data.status == 200;

  return res;
}

export {
  getDraftCostings,
  getActiveCostings,
  getCompleteCostings,
  getReviewCostings,
  getRestoreCostings,
  saveCostings,
  updateCostings,
  deleteCostings,
  restoreCosting,
  deleteLine,
  updateApproved,
  updateCompleted,
  requestApproval,
  getNotifications,
  saveNotifications,
  periodicCodeDelete,
  periodicCodeIndex,
  periodicCodeSync,
  periodicCodeSave,
  chargeCodeDelete,
  chargeCodeSync,
  chargeCodeSave,
  paymentMethodDelete,
  paymentMethodIndex,
  paymentMethodSync,
  paymentMethodSave,
  chargeCodeIndex,
  getCostingsSingle,
  duplicateCostings,
  downloadDocument,
  getCreditCheck,
  clientCreditCheck,
  saveCreditCheck,
  getLiveLines,
  saveLiveLines,
  deleteLiveLabour,
  deleteVariation,
  approveVariation,
  saveTotals,
  getLabourReport,
  getProfitReport,

  updateCostingCheck,
  overviewStore,
  overviewUpdate,
  overviewDelete,
  revenueStore,
  revenueUpdate,
  revenueDelete,
  labourStore,
  labourUpdate,
  labourDelete,
  variationStore,
  variationUpdate,
  variationDelete,
  liveLabourStore,
  liveLabourUpdate,
  liveLabourDelete
};
