/**
 * @description     \ Contracts middleware express routes
 * @baseURL         \ https://contract-micro-middleware
 */
import config from "../config";


/**
 * @method \ GET
 * @param  \ int
 * @return \ json
 */
const getEnquiryTypes = async () => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: false,
    endpoint: "/api/enquiryTypes",
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getContractSingle = async (id) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/contracts/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  const res = await data.json();

  return res;
};

const getVariationSingle = async (id) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/variations/${id}`,
    method: "GET",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  return res;
};


 /**
  * @method     \ POST
  * @param      \ obj
  * @return     \ json
  */
const saveContract = async (obj) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/contracts`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveVariation = async (obj) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: obj,
    endpoint: "/api/variations",
    method: "POST",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  return res;
};

const saveService = async (obj) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: obj,
    endpoint: "/api/services",
    method: "POST",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  return res;
};
   
const getActiveContracts = async (obj) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/contracts/activeIndex`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};
   
const getExpiredContracts = async (obj) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/contracts/expiredIndex`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};
   
const getPendingContracts = async (obj) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/contracts/pendingIndex`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};
   
const getPendingVariations = async (obj) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/variations/pendingIndex`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


/**
  * @method     \ PUT
  * @param      \ int
  * @param      \ obj
  * @return     \ json
  */
const updateContract = async (id, obj) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/contracts/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateVariation = async (id, obj) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/variations/${id}`,
    method: "PUT",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  return res;
};

const updateService = async (id, obj) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/services/${id}`,
    method: "PUT",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  return res;
};

const generateContract = async (id, obj) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/contracts/generate/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const generateVariation = async (id, obj) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/variations/generate/${id}`,
    method: "PUT",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  return res;
};


 /**
  * @method     \ DELETE
  * @param      \ int
  * @return     \ json
  */
const deleteContract = async (id) => {
  const options = {
    base: "https://contract-micro-middleware",
    parama: false,
    payload: false,
    endpoint: `/api/contracts/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteVariaton = async (id) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/variations/${id}`,
    method: "DELETE",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  return res;
};

const deleteService = async (id) => {
  const options = {
    base: "https://contract-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/services/${id}`,
    method: "DELETE",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  return res;
};



export {
  getEnquiryTypes,
  getContractSingle,
  getVariationSingle,
  saveContract,
  saveVariation,
  saveService,
  getActiveContracts,
  getExpiredContracts,
  getPendingContracts,
  getPendingVariations,
  updateContract,
  updateVariation,
  updateService,
  generateContract,
  generateVariation,
  deleteContract,
  deleteVariaton,
  deleteService,
};
