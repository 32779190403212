import React from "react";
import Icon from "@material-ui/core/icon";
import Button from "@material-ui/core/Button";

export default function AppButton({
  text,
  stylename,
  btnIcon,
  onClick,
  style,
}) {
  if (text && btnIcon) {
    return (
      <button
        color={stylename}
        onClick={onClick}
        style={{
          "border": "0px", "display": "inline-flex", "padding": "6px 16px", "borderRadius": "4px", "boxShadow": "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)", "fontSize": "0.875rem", "minWidth": "64px", "boxSizing": "border-box",
          "transition": "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif", "fontWeight": "500", "lineHeight": "1.75", "letterSpacing": "0.02857em", "textTransform": "uppercase", ...style
        }}
      >
        <span style={{ marginRight: 8 }} className="material-icons">{btnIcon}</span>
        <div>{text}</div>
      </button>
    );
  } else if (btnIcon) {
    return (
      <button
        color={stylename}
        onClick={onClick}
        style={{
          "border": "0px", "display": "inline-flex", "padding": "6px 16px", "borderRadius": "4px", "boxShadow": "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)", "fontSize": "0.875rem", "minWidth": "64px", "boxSizing": "border-box",
          "transition": "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif", "fontWeight": "500", "lineHeight": "1.75", "letterSpacing": "0.02857em", "textTransform": "uppercase", ...style
        }}
      >
        <span style={{ marginRight: 8 }} className="material-icons">{btnIcon}</span>
      </button>
    );
  } else if (text) {
    return (
      <button
        color={stylename}
        onClick={onClick}
        style={{
          "border": "0px", "display": "inline-flex", "padding": "6px 16px", "borderRadius": "4px", "boxShadow": "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)", "fontSize": "0.875rem", "minWidth": "64px", "boxSizing": "border-box",
          "transition": "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif", "fontWeight": "500", "lineHeight": "1.75", "letterSpacing": "0.02857em", "textTransform": "uppercase", ...style
        }}
      >
        <div>{text}</div>
      </button>
    );
  }
}
